import React from "react";

export default function PageWithBackgroundImage({ children, location }) {
  return (
    <div
      style={{
        position: "absolute",
        height: "100vh",
        top: 0,
        left: 0,
        zIndex: 0,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 1,
          height: "100vh",
        }}
      >
        {children}
      </div>
    </div>
  );
}
